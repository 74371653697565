
























































































import enums from '@/enums'
import { defineComponent, computed, ref, PropType } from '@vue/composition-api'
import {
  ITransaction,
  ITransactionFilters,
} from '@/modules/transaction/resources'
import { TransactionSyncStatusBadge } from '@/components/transaction/TransactionDetail/TransactionSyncStatusBadge'
import { DeliveriesChart } from '@/components/transaction/TransactionDetail/DeliveriesChart'
import { TransactionDocuments } from '@/components/transaction/TransactionDetail/TransactionDocuments'
import { TransactionMainInfo } from '@/components/common/TransactionMainInfo'
import { TransactionMixin } from '@/mixins/TransactionMixin'
import AddressComponent from '@/components/common/AddressComponent.vue'
import {
  ITransactionDelivery,
  ITransactionSyncStatus,
  IDeliveryDocument,
  IUser,
} from '@/interfaces'
import { PageTitle } from '@/components/atoms/PageTitle'

export default defineComponent({
  name: 'TransactionInfo',
  components: {
    PageTitle,
    DeliveriesChart,
    AddressComponent,
    TransactionSyncStatusBadge,
    TransactionMainInfo,
    TransactionDocuments,
  },
  mixins: [TransactionMixin],
  props: {
    transaction: {
      type: Object as PropType<ITransaction>,
      default: () => null,
    },
    deliveries: {
      type: Array as PropType<ITransactionDelivery[]>,
      default: () => null,
    },
    isLoading: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
    syncStatus: {
      type: Object as PropType<ITransactionSyncStatus>,
      default: () => null,
    },
    filters: {
      type: Object as PropType<ITransactionFilters>,
      default: () => null,
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => null,
    },
  },
  emits: ['query'],
  setup(props) {
    const rawData = ref(!!props.filters.raw)
    const offer = computed(() => props.transaction.offer)
    const offerStation = computed(
      () => offer.value?.sourceStation || offer.value?.destinationStation || {}
    )
    const partner = computed(() =>
      [props.transaction.buyer, props.transaction.seller].find(
        (user) => user.organisation.id !== props.transaction.organisationId
      )
    )
    const deliveriesWeightNotes = computed(() => {
      const documents: IDeliveryDocument[] = []
      const deliveryNumbersIncluded = new Set<number>()
      if (!props.deliveries) {
        return documents
      }

      props.deliveries.forEach((delivery: ITransactionDelivery) => {
        delivery.documents?.forEach((document: IDeliveryDocument) => {
          const isSameTransaction = document.filePath.includes(
            props.transaction.transactionNumber
          )
          if (
            isSameTransaction &&
            !deliveryNumbersIncluded.has(delivery.deliveryNumber)
          ) {
            documents.push({
              deliveryId: String(delivery.deliveryNumber),
              ...document,
            })
            deliveryNumbersIncluded.add(delivery.deliveryNumber)
          }
        })
      })

      const sortedDocuments = documents.sort(
        (a: IDeliveryDocument, b: IDeliveryDocument) => {
          if ((a.deliveryId || '') > (b.deliveryId || '')) return 1
          if ((a.deliveryId || '') < (b.deliveryId || '')) return -1
          return 0
        }
      )

      return sortedDocuments
    })

    return {
      offer,
      offerStation,
      partner,
      rawData,
      deliveriesWeightNotes,
      enums,
    }
  },
})
