






























import {
  defineComponent,
  ref,
  getCurrentInstance,
  computed,
  onMounted,
  PropType,
} from '@vue/composition-api'
import enums, { DeliveryRawSyncStatus, DeliverySystemSyncStatus } from '@/enums'
import { IOpts, ITransactionDelivery } from '@/interfaces'
import {
  ITransaction,
  ITransactionFilters,
} from '@/modules/transaction/resources'
import {
  deliverySystemStatusColour,
  deliveryRawStatusColour,
} from '@/utils/utils'

export default defineComponent({
  name: 'DeliveriesChart',
  props: {
    deliveries: {
      type: Array as PropType<ITransactionDelivery[]>,
      default: () => null,
    },
    transaction: {
      type: Object as PropType<ITransaction>,
      default: () => null,
    },
    filters: {
      type: Object as PropType<ITransactionFilters>,
      default: () => null,
    },
  },
  emits: ['query'],
  setup(props, { emit, root }) {
    const vm = getCurrentInstance()?.proxy
    const router = root.$options.router
    const type = ref(enums.ChartType.Donut)
    const totalDeliveriesNumber = computed(() =>
      Math.floor(
        props.transaction?.deliveryAmount?.totalAmount /
          props.transaction?.offer?.guaranteedUnitAmount
      )
    )

    onMounted(() => {
      const hasUnplannedDeliveries = props.deliveries?.some(
        (delivery: ITransactionDelivery) =>
          delivery.status === DeliveryRawSyncStatus.Unplanned
      )

      if (!hasUnplannedDeliveries && !router?.currentRoute.query.status) {
        emit('query', { status: null })
      }
    })

    const isRawDataShown = computed(() => props.filters?.raw)

    const deliveryStatusColour = computed(() =>
      isRawDataShown.value
        ? deliveryRawStatusColour
        : deliverySystemStatusColour
    )

    const unplannedDeliveries = computed(
      () =>
        Math.max(
          0,
          Number(totalDeliveriesNumber.value - props.deliveries?.length)
        ) || 0
    )

    const defaultStatus = computed(() =>
      Object.values(
        isRawDataShown.value ? DeliveryRawSyncStatus : DeliverySystemSyncStatus
      )
        .filter((status) => !['open', 'synced'].includes(status))
        .reduce((acc, item) => {
          if (item === DeliverySystemSyncStatus.Unplanned) {
            return { ...acc, [item]: unplannedDeliveries.value }
          }
          return { ...acc, [item]: 0 }
        }, {})
    )

    const deliveriesByStatus = computed(() =>
      Object.assign(
        {},
        defaultStatus.value,
        props.deliveries?.reduce(
          (acc: { [key: string]: number }, delivery: ITransactionDelivery) => {
            const statusKey = isRawDataShown.value ? 'status' : 'deliveryStatus'
            return {
              ...acc,
              [delivery[statusKey]]: (acc[delivery[statusKey]] ?? 0) + 1,
            }
          },
          {}
        )
      )
    )

    const statusesToShow = computed(() =>
      Object.keys(deliveriesByStatus.value)
        .filter((key: string) => !!deliveriesByStatus.value[key])
        .reduce(
          (acc, key) => ({ ...acc, [key]: deliveriesByStatus.value[key] }),
          {}
        )
    )

    const statusColorsToShow = computed(() =>
      Object.keys(deliveryStatusColour.value).reduce(
        (acc: string[], colour: string) => {
          if (Object.keys(statusesToShow.value).includes(colour)) {
            acc.push(deliveryStatusColour.value[colour])
          }

          return acc
        },
        []
      )
    )

    const handleLegendClick = (status: string) => {
      const chart = vm?.$refs?.chart as any

      chart?.resetSeries()
      chart?.toggleSeries(status)

      emit('query', { status })
    }

    const series = computed(() => Object.values(statusesToShow.value))
    const chartOptions = computed(() => ({
      chart: {
        type: type.value,
        animations: {
          enabled: false,
        },
        events: {
          dataPointSelection: (_: any, __: any, config: any) => {
            emit('query', {
              status: Object.keys(statusesToShow.value)[config?.dataPointIndex],
            })
          },
        },
      },
      tooltip: {
        y: {
          title: {
            formatter: (status: string) =>
              isRawDataShown.value
                ? status
                : vm?.$i18n?.t(`deliveryManager.status.${status}`),
          },
        },
      },
      colors: statusColorsToShow.value,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                label: vm?.$i18n?.t('transaction.info.order'),
                fontSize: '16px',
                fontFamily: '"Beausite Semibold", sans-serif',
                showAlways: true,
                show: true,
              },
              value: {
                show: true,
                fontSize: '14px',
                fontFamily: '"Beausite Regular", sans-serif',
                fontWeight: 400,
                offsetY: 6,
              },
            },
          },
        },
      },
      legend: {
        formatter: (seriesName: string, opts: IOpts) => [
          seriesName,
          `(${opts.w.globals.series[opts.seriesIndex]})`,
        ],
      },
      labels: Object.keys(statusesToShow.value),
    }))

    return {
      series,
      type,
      chartOptions,
      statusesToShow,
      defaultStatus,
      deliveryStatusColour,
      deliveriesByStatus,
      isRawDataShown,
      handleLegendClick,
    }
  },
})
