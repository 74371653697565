





















import { defineComponent, SetupContext, onMounted } from '@vue/composition-api'
import { TransactionInfo } from '@/components/transaction/TransactionDetail/TransactionInfo'
import { DeliveriesTable } from '@/components/transaction/TransactionDetail/DeliveriesTable'
import useTransaction, {
  ITransactionFilters,
} from '@/components/transaction/TransactionDetail/TransactionDetailPage/composable'
import { IDeliveryDocument, IUser } from '@/interfaces'

export default defineComponent({
  name: 'TransactionDetailPage',
  components: {
    TransactionInfo,
    DeliveriesTable,
  },
  setup(_, context: SetupContext) {
    const {
      loadTransactionDetails,
      loadTransactionSyncStatus,
      loadTransactionDeliveries,
      handlePDFDownload,
      handleAllPDFDownload,
      getFiltersFromQuery,
      getTransaction,
      getSyncStatus,
      getDeliveries,
      getTransactionsLoading,
      getSyncLoading,
      getDeliveriesLoading,
      getFilteredDeliveries,
      updateQuery,
      user,
    } = useTransaction(context)

    const { root } = context

    onMounted(async () => {
      const transactionId = root.$route.params.id

      await Promise.all([
        loadTransactionDetails(
          (user.value as IUser).organisationId,
          transactionId
        ),
        loadTransactionSyncStatus(transactionId),
        loadTransactionDeliveries(transactionId),
      ])
    })

    const onUpdateQuery = (filters: ITransactionFilters) => {
      updateQuery(filters)
    }

    const onWeightNoteDownload = async (note: IDeliveryDocument) => {
      await handlePDFDownload(note)
    }

    const onWeightNoteAllDownload = async () => {
      await handleAllPDFDownload(getTransaction.value?.transactionNumber || '')
    }

    return {
      transactionDetail: getTransaction,
      syncStatus: getSyncStatus,
      deliveries: getDeliveries,
      filteredDeliveries: getFilteredDeliveries,
      filters: getFiltersFromQuery,
      isTransactionsLoading: getTransactionsLoading,
      isSyncLoading: getSyncLoading,
      isDeliveriesLoading: getDeliveriesLoading,
      user,
      onUpdateQuery,
      onWeightNoteDownload,
      onWeightNoteAllDownload,
    }
  },
})
