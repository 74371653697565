var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.$style.section},[_c('page-title',{attrs:{"title":_vm.$t('transaction.info.order'),"is-secondary":""}}),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"items":_vm.tableItems,"headers":_vm.tableHeaders,"disable-pagination":"","disable-filtering":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("toUnixTime")(item.deliveryDate)))+" ")]}},{key:"item.deliveryStatus",fn:function(ref){
var item = ref.item;
return [(_vm.filters.raw)?_c('delivery-state-history',{attrs:{"delivery":item}}):_c('span',[_vm._v(_vm._s(_vm.$t(("deliveryManager.status." + (item.deliveryStatus)))))])]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.carrier.name)+" ")]}},{key:"item.carrierId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.carrier.id)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }