import HttpService from '@/services/HttpService/HttpService'
import { ITransaction } from '@/modules/transaction/resources'
import { ITransactionDelivery, ITransactionSyncStatus } from '@/interfaces'

export const getTransactionDetails = async (
  organisationId: string,
  transactionId: string
): Promise<ITransaction> => {
  const response = await HttpService.request({
    path: `/organisations/${organisationId}/transactions/${transactionId}`,
  })

  return response as ITransaction
}

export const getTransactionSyncStatus = async (
  transactionId: string
): Promise<ITransactionSyncStatus> => {
  const response = await HttpService.request({
    path: `/sync-queue/transactions/${transactionId}`,
  })

  return response as ITransactionSyncStatus
}

export const getTransactionDeliveries = async (
  transactionId: string
): Promise<ITransactionDelivery[]> => {
  const response = await HttpService.request({
    path: `/sync-queue/transactions/${transactionId}/deliveries`,
  })

  return response as ITransactionDelivery[]
}
