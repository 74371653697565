





















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ITransactionSyncStatus } from '@/interfaces'
import { TransactionSyncStatus } from '@/enums'
import { formatDateTime, toUnixTime } from '@/utils/utils'

export default defineComponent({
  name: 'TransactionSyncStatusBadge',
  props: {
    transactionSyncStatus: {
      type: Object as PropType<ITransactionSyncStatus>,
      default: () => null,
    },
  },
  setup(props) {
    const badgeColor = computed(() => {
      let type: string = ''

      switch (props.transactionSyncStatus?.syncStatus) {
        case TransactionSyncStatus.Done:
          type = 'success'
          break
        case TransactionSyncStatus.Open:
          type = 'warning'
          break
        case TransactionSyncStatus.Failed:
          type = 'error'
          break
        case TransactionSyncStatus.Cancelled:
          type = 'info'
      }

      return type
    })

    const formattedDate = computed(() =>
      formatDateTime(toUnixTime(props.transactionSyncStatus?.updatedAt))
    )

    return { badgeColor, formattedDate }
  },
})
