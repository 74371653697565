




































































import { defineComponent, PropType } from '@vue/composition-api'
import { ITransaction } from '@/modules/transaction/resources'
import { TransactionMixin } from '@/mixins/TransactionMixin'
import { IDeliveryDocument } from '@/interfaces'

export default defineComponent({
  name: 'TransactionDocuments',
  mixins: [TransactionMixin],
  props: {
    transaction: {
      type: Object as PropType<ITransaction>,
      default: () => null,
    },
    weightNotes: {
      type: Array as PropType<IDeliveryDocument[]>,
      default: () => null,
    },
  },
  setup() {},
})
