
































import {
  computed,
  defineComponent,
  PropType,
  watch,
  ref,
} from '@vue/composition-api'
import { ITransactionDelivery } from '@/interfaces'
import { DeliveryStateHistory } from '@/components/transaction/TransactionDetail/DeliveryStateHistory'
import { ITransactionFilters } from '@/modules/transaction/resources'
import { PageTitle } from '@/components/atoms/PageTitle'

export default defineComponent({
  name: 'DeliveriesTable',
  components: {
    PageTitle,
    DeliveryStateHistory,
  },
  props: {
    deliveries: {
      type: Array as PropType<ITransactionDelivery[]>,
      default: () => null,
    },
    isLoading: {
      type: Boolean as PropType<Boolean>,
      default: () => false,
    },
    filters: {
      type: Object as PropType<ITransactionFilters>,
      default: () => null,
    },
  },
  setup(props, { root }) {
    const i18n = root.$i18n
    const tableItems = computed(() => props.deliveries ?? [])
    const tableColumnNames = ref<Record<string, string>>({})

    const tableColumnNamesRaw = {
      deliveryDate: 'Delivery date',
      deliveryNumber: 'Delivery number',
      deliveryStatus: 'Delivery status',
      carrier: 'Carrier name',
      carrierId: 'Carrier id',
      licensePlate: 'License plate',
      weightNumber: 'Weight note no.',
      unitCount: 'Unit count',
      unitAmount: 'Unit amount (t)',
      moistness: 'Moistness',
    }

    const tableColumnNamesTranslated = {
      deliveryDate: i18n.t('deliveryManager.table.deliveryDate') as string,
      deliveryNumber: i18n.t('deliveryManager.table.deliveryNumber') as string,
      deliveryStatus: i18n.t('deliveryManager.table.deliveryStatus') as string,
      carrier: i18n.t('deliveryManager.table.carrier') as string,
      carrierId: i18n.t('deliveryManager.table.carrierId') as string,
      licensePlate: i18n.t('deliveryManager.table.licensePlate') as string,
      weightNumber: i18n.t('deliveryManager.table.weightNote') as string,
      unitCount: i18n.t('deliveryManager.table.unitCount') as string,
      unitAmount: i18n.t('deliveryManager.table.unitAmount') as string,
      moistness: i18n.t('deliveryManager.table.moistness') as string,
    }

    watch(
      () => root.$route.query,
      () => {
        tableColumnNames.value = props.filters.raw
          ? tableColumnNamesRaw
          : tableColumnNamesTranslated
      },
      { immediate: true }
    )

    const tableHeaders = computed(() => [
      { text: tableColumnNames.value.deliveryDate, value: 'deliveryDate' },
      { text: tableColumnNames.value.deliveryNumber, value: 'deliveryNumber' },
      { text: tableColumnNames.value.deliveryStatus, value: 'deliveryStatus' },
      { text: tableColumnNames.value.carrier, value: 'carrier' },
      { text: tableColumnNames.value.carrierId, value: 'carrierId' },
      { text: tableColumnNames.value.licensePlate, value: 'licensePlate' },
      { text: tableColumnNames.value.weightNumber, value: 'weightNumber' },
      { text: tableColumnNames.value.unitCount, value: 'unitCount' },
      {
        text: tableColumnNames.value.unitAmount,
        value: 'unitAmount',
        width: 160,
      },
      { text: tableColumnNames.value.moistness, value: 'moistness' },
    ])

    return { tableItems, tableHeaders }
  },
})
