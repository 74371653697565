import { computed, reactive, SetupContext } from '@vue/composition-api'
import {
  getTransactionDeliveries,
  getTransactionDetails,
  getTransactionSyncStatus,
} from './resources'
import { ITransaction } from '@/modules/transaction/resources'
import {
  ITransactionDelivery,
  IDeliveryDocument,
  ITransactionSyncStatus,
} from '@/interfaces'
import { StatusCode } from '@/services/HttpService/HttpService'
import { DeliveryRawSyncStatus, S3DocumentType } from '@/enums'
import { filterNormalizer } from '@/utils/utils'
import { normalizeFilterRules } from './rules'
import { downloadPDF } from '@/components/delivery/DeliveryManagement/DeliveryManagementPage/resources'
import { downloadFile, openUrl } from '@/utils/DocumentUtil/DocumentUtil'

interface IFetchQuery {
  status?: string
  raw?: string
}

export interface ITransactionFilters {
  status?: DeliveryRawSyncStatus
  raw?: boolean
}

interface ITransactionInfo {
  transaction: ITransaction | null
  syncStatus: ITransactionSyncStatus | null
  deliveries: ITransactionDelivery[] | null
  isTransactionsLoading: boolean
  isSyncLoading: boolean
  isDeliveriesLoading: boolean
  query: IFetchQuery | null
  filters: ITransactionFilters
}

export default function useTransaction(context: SetupContext) {
  const router = context.root.$options.router

  const getDefaultFilters = () =>
    ({
      status: DeliveryRawSyncStatus.Unplanned,
      raw: false,
    } as ITransactionFilters)

  const state = reactive({
    transaction: null,
    syncStatus: null,
    deliveries: null,
    isTransactionsLoading: false,
    isSyncLoading: false,
    isDeliveriesLoading: false,
    query: null,
    filters: {
      ...getDefaultFilters(),
      ...router?.currentRoute.query,
    },
  } as ITransactionInfo)

  const user = computed(() => context.root.$store.getters['auth/getUser'])

  const setTransaction = (transaction: ITransaction) => {
    state.transaction = transaction
  }

  const setSyncStatus = (syncStatus: ITransactionSyncStatus | null) => {
    state.syncStatus = syncStatus
  }

  const setDeliveries = (deliveries: ITransactionDelivery[] | null) => {
    state.deliveries = deliveries
  }

  const setFiltersFromQuery = (filters: ITransactionFilters | null) => {
    state.filters = {
      ...state.filters,
      ...filters,
    }
  }

  const loadTransactionDetails = async (
    organisationId: string,
    transactionId: string
  ) => {
    try {
      state.isTransactionsLoading = true
      const transaction = (await getTransactionDetails(
        organisationId,
        transactionId
      )) as ITransaction

      setTransaction(transaction)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      state.isTransactionsLoading = false
    }
  }

  const loadTransactionSyncStatus = async (transactionId: string) => {
    try {
      state.isSyncLoading = true
      const syncStatus = (await getTransactionSyncStatus(
        transactionId
      )) as ITransactionSyncStatus

      setSyncStatus(syncStatus)
    } catch (error: any) {
      if (error.response?.status === StatusCode.PageNotFound) {
        setSyncStatus(null)
        return
      }

      throw new Error(error)
    } finally {
      state.isSyncLoading = false
    }
  }

  const loadTransactionDeliveries = async (transactionId: string) => {
    try {
      state.isDeliveriesLoading = true
      const deliveries = (await getTransactionDeliveries(
        transactionId
      )) as ITransactionDelivery[]

      setDeliveries(deliveries)
    } catch (error: any) {
      if (error.response?.status === StatusCode.PageNotFound) {
        setDeliveries(null)
        return
      }

      throw new Error(error)
    } finally {
      state.isDeliveriesLoading = false
    }
  }

  const getTransaction = computed(() => state.transaction)
  const getSyncStatus = computed(() => state.syncStatus)
  const getTransactionsLoading = computed(() => state.isTransactionsLoading)
  const getSyncLoading = computed(() => state.isSyncLoading)
  const getDeliveriesLoading = computed(() => state.isDeliveriesLoading)
  const getFiltersFromQuery = computed(() => state.filters)
  const getQueryFromFilters = computed(() =>
    filterNormalizer(state.filters, normalizeFilterRules)
  )
  const getDeliveries = computed(() => state.deliveries)
  const getFilteredDeliveries = computed(() =>
    state.deliveries
      ?.filter((delivery: ITransactionDelivery) =>
        state.filters.status
          ? delivery[state.filters.raw ? 'status' : 'deliveryStatus'] ===
            state.filters.status
          : true
      )
      .sort(
        (a: ITransactionDelivery, b: ITransactionDelivery) =>
          a.deliveryDate - b.deliveryDate
      )
  )

  const updateQuery = async (filters: ITransactionFilters) => {
    setFiltersFromQuery(filters)

    await router?.push({
      query: getQueryFromFilters.value,
    })
  }

  const handlePDFDownload = async (document: IDeliveryDocument) => {
    const deliveryId = document.filePath.split('/').pop()
    const fileName = `Wiegenote-${deliveryId}.pdf`

    try {
      const filePath = `${document.filePath}/Wiegenote.pdf`
      const { url } = await downloadPDF(filePath)
      const pdf = await fetch(url)
      const blob = await pdf.blob()

      openUrl(window.URL.createObjectURL(blob), true, fileName)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const handleAllPDFDownload = async (transactionNumber: string) => {
    try {
      const { url } = await downloadFile(
        transactionNumber,
        S3DocumentType.DeliveryWeightNoteAll,
        `deliveries_${transactionNumber}.zip`
      )

      openUrl(url, true)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return {
    user,
    loadTransactionDetails,
    loadTransactionSyncStatus,
    loadTransactionDeliveries,
    handlePDFDownload,
    handleAllPDFDownload,
    updateQuery,
    getTransaction,
    getSyncStatus,
    getDeliveries,
    getTransactionsLoading,
    getSyncLoading,
    getDeliveriesLoading,
    getFiltersFromQuery,
    getFilteredDeliveries,
  }
}
