































import { computed, ref, defineComponent, PropType } from '@vue/composition-api'
import { ITransactionDelivery } from '@/interfaces'
import { DeliveryRawSyncStatus } from '@/enums'

export default defineComponent({
  name: 'DeliveryStateHistory',
  props: {
    delivery: {
      type: Object as PropType<ITransactionDelivery>,
      default: () => null,
    },
  },
  setup(props) {
    const stateHistory = computed(() =>
      props.delivery?.history.filter((update: any) => !!update.new.status)
    )

    const useColor = (status: DeliveryRawSyncStatus) => {
      switch (status) {
        case DeliveryRawSyncStatus.Invoiced:
          return '#2391e1' // $--color-info
        case DeliveryRawSyncStatus.Deleted:
          return '#f56c6c' // $--color-danger
        case DeliveryRawSyncStatus.Unloaded:
          return '#00af7d' // $--color-success
        case DeliveryRawSyncStatus.Dispatched:
          return '#76a2d6' // custom color
        case DeliveryRawSyncStatus.Import:
          return '#fff2c1' // $--color-recurring
        case DeliveryRawSyncStatus.Insert:
          return '#c5B375' // custom color
        case DeliveryRawSyncStatus.Unplanned:
          return '#cdcdcd' // $--color-container-border
      }
    }

    const visible = ref(false)

    const onMenuInput = (isVisible: boolean) => {
      visible.value = isVisible
    }

    return { stateHistory, useColor, visible, onMenuInput }
  },
})
